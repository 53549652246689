//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalChangePassword from "@/components/User/ModalChangePassword";
import Alert from "@/components/Alert/Alert";
export default {
  meta: {
    title: "帐号设定"
  },
  components: {
    ModalChangePassword,
    Alert
  },
  data() {
    return {
      passwordChanged: false,
      childsInfo: null
    };
  },
  created() {
    return Promise.all([this.$store.dispatch("user/getUser", {
      force: true
    }), this.loadChildsInfo()]);
  },
  methods: {
    async loadChildsInfo() {
      this.childsInfo = await this.$store.dispatch("user/getChilds");
      console.log('this.childsInfo=', this.childsInfo);
    },
    async onInviteChild() {
      await this.$modal.process(() => import("@/components/User/ModalInviteChild"));
      await this.loadChildsInfo();
    },
    async onDisableChild(item) {
      await this.$modal.process(() => import("@/components/User/ModalDisableChild"), {
        linkId: item.link.id,
        email: item.link.child.user_email_address
      });
      await this.loadChildsInfo();
    },
    async onEnableChild(item) {
      await this.$store.dispatch("user/enableChild", {
        id: item.link.id
      }), await this.loadChildsInfo();
    }
  },
  computed: {
    user() {
      console.log('this.$store.state.user.user=', this.$store.state.user.user);
      return this.$store.state.user.user;
    },
    countConnected() {
      var _this$childsInfo;
      if (!this.childsInfo) {
        return null;
      }
      return ((_this$childsInfo = this.childsInfo) === null || _this$childsInfo === void 0 ? void 0 : _this$childsInfo.childs.filter(t => !t.disabled).length) + 1;

      /* &:
      let childs = this.childsInfo?.childs;
      return childs && childs.length ?
          childs.filter((t) => !t.disabled).length + 1
          : 1 ;
      */
    },

    countAvailable() {
      if (!this.childsInfo) {
        return null;
      }
      return this.childsInfo.users_quota + 1;
    },
    canEnable() {
      return this.childsInfo.users_quota_remaining;
    },
    childs() {
      var _this$childsInfo2;
      if (!this.childsInfo) {
        return null;
      }

      //@V:
      return [...((_this$childsInfo2 = this.childsInfo) === null || _this$childsInfo2 === void 0 ? void 0 : _this$childsInfo2.childs)].sort((a, b) => {
        return (b.disabled ? 0 : 1) - (a.disabled ? 0 : 1);
      });

      /* &:
      let childs = this.childsInfo.childs;
      return childs && childs.length ?
          childs.sort((a, b) => {
              return (b.disabled ? 0 : 1) - (a.disabled ? 0 : 1);
          })
          : null
      */
    }
  }
};